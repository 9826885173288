import AbstractService from '@/services/abstractService'

class SliderService extends AbstractService {
  endpoint = 'slider'
  put(id, data, params = null) {

    // define append form
    const formData = new FormData()

    for (const key in data) {
      if(key === 'image' && data[key] === null){
        continue;
      }
      formData.append(key, data[key])
    }
    return this.http.put(`${this.endpoint}/${id}`, formData, params)
  }

}

const Service = new SliderService()

export default Service
