<script
    src="../../../../../../../AppData/Local/Temp/Rar$DRa14036.14120/src/app/helper/alertTimer/alert-timer.service.ts"
></script>
<template>
  <!-- The Modal -->
  <div
      id="createModal"
      class="modal"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <button
              type="button"
              class="close"
              data-dismiss="modal"
          >
            &times;
          </button>
        </div>
        <!-- Modal body -->
        <div class="">
          <!--Start-->
          <b-card-code
              title="Create Slide"
          >
            <!-- form -->
            <validation-observer ref="simpleRules">
              <b-form>
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <label for="title">Title</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Title"
                          rules="required"
                      >
                        <b-form-input
                            id="title"
                            v-model="createdSlide.title"
                            name="title"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <label for="sort">Sort</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Sort"
                          rules="required"
                      >
                        <b-form-input
                            id="sort"
                            v-model="createdSlide.sort"
                            name="sort"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
               <b-row>
                 <b-col md="12">
                   <b-form-group>
                     <label for="image">Image</label>
                     <validation-provider
                         #default="{ errors }"
                         name="Image"
                         rules=""
                     >
                       <vue-dropzone
                           id="image"
                           ref="slideImageRef"
                           name="image"
                           :options="dropzoneOptions"
                       />
                       <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>
                   </b-form-group>
                 </b-col>
               </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <label for="description">Description</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Description"
                          rules="required"
                      >
                        <b-form-textarea
                            id="description"
                            v-model="createdSlide.description"
                            name="description"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                            rows="5"
                            cols="12"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Modal footer -->
                <div class="modal-footer">
                  <button
                      type="button"
                      class="btn btn-primary"
                      @click.prevent="validationForm"
                  >
                    Submit
                  </button>
                </div>
              </b-form>
            </validation-observer>

            <template #code>
              {{ codeMultiple }}
            </template>
          </b-card-code>
          <!--End-->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BFormTextarea,
} from 'bootstrap-vue'
import {
  required, email, confirmed, password,
} from '@validations'
import vSelect from 'vue-select'
import vue2Dropzone from 'vue2-dropzone'
import {codeMultiple, codeBasic} from './code'
import FormTextAreaDefault from '../../../shared/form-textarea/FormTextAreaDefault.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import ToastificationContent from '@core/components/toastification/ToastificationContent'
import sliderService from '../../../services/sliderService'
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,

    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormTextarea,
    FormTextAreaDefault,
    vSelect,
    vueDropzone: vue2Dropzone,
    quillEditor
  },
  data() {
    return {
      required,
      codeMultiple,
      createdSlide: {
        title: '',
        description: '',
        sort: '',
        image: ''
      },
      codeBasic,
      snowOption: {
        theme: 'snow',
      },
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        addRemoveLinks: true,
        acceptedFiles: '.jpeg,.jpg,.png,.gif',
        dictRemoveFile: 'Remove',
        maxFiles: 1,
        init: function() {
          this.on("maxfilesexceeded", function(file) {
            this.removeAllFiles();
            this.addFile(file);
          });
        },
      },
    }
  },
  mounted() {

  },
  methods: {

    validationForm() {
      this.$refs.simpleRules.validate()
          .then(success => {
            if (success) {
              this.createdSlide.image = this.$refs.slideImageRef.getAcceptedFiles()[0]
              sliderService.post(this.createdSlide).then(response => {
                if (response && response.status === 200) {
                  this.$emit('refresh', 'true')
                  $('#createModal').modal('hide')
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Success',
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: 'Slide Is Created Successfully !',
                    },
                  })
                }
                this.loading = false
              })

            }
          }).catch(function (error) {

        $('#createModal').modal('hide')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            variant: 'Error',
            text: 'Error !',
          },
        })
        return Promise.reject(error)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

label {
  font-weight: bold;
}

#createModal svg {
  filter: invert(69%) sepia(14%) saturate(4763%) hue-rotate(3deg) brightness(100%) contrast(43%) !important;
}
</style>
